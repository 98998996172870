export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'GridIcon'
  },
  {
    title: 'การขาย',
    route: 'sales_order',
    icon: 'ShoppingCartIcon'
  },
  {
    title: 'คลัง',
    route: 'store',
    icon: 'HomeIcon'
  },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotion',
  //   icon: 'GiftIcon'
  // },
  {
    title: 'การจัดการ',
    route: 'setting',
    icon: 'SettingsIcon'
  },
  {
    header: 'สินค้า'
  },
  {
    title: 'จัดการสินค้า',
    icon: 'CopyIcon',
    route: 'product_list'
  },
  {
    title: 'เพิ่มสินค้า',
    icon: 'PlusSquareIcon',
    route: 'product_add'
  },
  {
    header: 'ออเดอร์'
  },
  {
    title: 'รายการออเดอร์',
    icon: 'ListIcon',
    route: 'orderlist',
    tag: '3',
    tagVariant: 'light-danger'
  },
  {
    header: 'ลูกค้า & ตัวแทน'
  },
  {
    title: 'จัดการลูกค้า',
    icon: 'UserIcon',
    route: 'customer_list'
  },
  {
    title: 'จัดการตัวแทน',
    icon: 'UserIcon',
    route: 'agent_list'
  },
  {
    title: 'เพิ่มลูกค้า',
    icon: 'UserPlusIcon',
    route: 'customer_add'
  },
  {
    header: 'พนักงาน'
  },
  {
    title: 'จัดการพนักงาน',
    icon: 'UsersIcon',
    route: 'employee_list'
  },
  {
    title: 'เพิ่มพนักงาน',
    icon: 'UserPlusIcon',
    route: 'employee_add'
  },
  {
    header: 'รายงาน'
  },
  {
    title: 'รายงานการขาย',
    icon: 'ClipboardIcon',
    route: 'reportorder_all'
  },
  {
    title: 'อันดับสินค้าขายดี',
    icon: 'ClipboardIcon',
    route: 'reportproductsell'
  }
]
